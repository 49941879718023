.detail {
  display: flex;
  flex-direction: row;
  margin: 0 0 0.6rem 0;
}

.icon {
  display: block;
  flex: 0 0 1.2rem;
  max-height: 1.6rem;
  max-width: 1.2rem;
  background: var(--color-common);
  margin: 0.15rem 0.5rem 0 0;
}

.email {
  display: block;
  overflow-wrap: break-word;
  width: 100%;
  word-wrap: break-word;
}

.address_detail {
  display: block;
}
