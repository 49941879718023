.layout {
  animation: fadein 1.5s;
  background-color: var(--color-bg-highlight);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: var(--bp-l);
}

.header,
.aside,
.main,
.footer {
  padding: 0 1rem;
}

@media print, (min-width: 46rem) {
  .layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .header {
    flex-grow: 0;
    flex: 0 0 100%;
    order: 1;
  }

  .aside {
    flex: 0 0 30%;
    max-width: 30%;
    order: 2;
    padding: 0 0 0 1rem;
  }

  .main {
    flex: 0 0 70%;
    max-width: 70%;
    order: 3;
    padding: 0 1rem 0 0;
  }

  .footer {
    flex: 0 0 100%;
    order: 4;
  }
}

@media (min-width: 75rem) {
  .layout {
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 0.3rem;
    margin: 5rem auto;

    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
      0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072),
      0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
