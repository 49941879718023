.h2 {
    border-bottom: 1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(
        to left,
        rgba(0, 0, 0, 0),
        var(--color-inferior-alternate) 75%
    );
    font-size: 1.5rem;
    font-weight: var(--font-weight-bold);
    margin: 0 0 1.5rem 0;
    padding: 0 0 0.2rem 0;
    text-transform: uppercase;
}
