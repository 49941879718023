.situation_list {
    margin: 0;
    padding: 0;
}

.situation_title {
    margin: 0 0 var(--font-size-xs) 0;
    padding: 0;
}

.position {
    display: block;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-bold);
}

.company {
    display: block;
    margin: 0;
    padding: 0;
    font-size: var(--font-size-m);
    color: var(--color-accent);
}

.period {
    margin: 0;
    padding: 0;
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-light);
    color: var(--color-accent);
}

.period time:first-child::after {
    content: ' - ';
}

.situation_detail {
    margin: 0 0 var(--font-size-l) 0;
    padding: 0;
}

.description {
    margin: 0 0 var(--font-size-xs) 0;
}

.highlights {
    display: block;
}

.highlight {
    position: relative;
    margin: 0 0 var(--font-size-xs) 1.2em;
}
.highlight:last-child {
    margin-bottom: 0;
}

.highlight::before {
    content: '';
    position: absolute;
    top: 0.45em;
    left: -1em;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: var(--color-accent);
}

@media (min-width: 46rem) {
}

@media (min-width: 75rem) {
    .situation_list {
        display: flex;
        flex-flow: row wrap;
    }

    .situation_title {
        text-align: right;
        position: relative;
        flex: 1 30%;
        max-width: 30%;
        border-right: 1px solid var(--color-inferior-alternate);
        margin: 0;
        padding: 0 1rem var(--font-size-xl) 0;
    }

    .situation_title::before {
        content: '';
        height: 11px;
        width: 11px;
        position: absolute;
        right: -6px;
        top: 1.5rem;
        background-color: var(--color-inferior);
        border: 1px solid var(--color-inferior-alternate);
        border-radius: 50%;
    }

    .situation_title:last-of-type {
        border-image: linear-gradient(
                to bottom,
                var(--color-inferior-alternate) 50%,
                rgba(0, 0, 0, 0)
            )
            1 100%;
    }

    .situation_title:first-of-type {
        border-image: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0),
                var(--color-inferior-alternate) 1em
            )
            1 100%;
    }

    .position {
        direction: rtl;
    }

    .situation_detail {
        flex: 1 70%;
        margin: 0;
        padding: 0 0 5rem 1rem;
    }
}
